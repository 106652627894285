
import CustomButton from "@/components/CustomButton.vue";
import { PopoutsModule } from "@/store/modules/popouts";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfilePopout",
  components: { CustomButton },
  props: {
    identity: {
      type: String,
      required: true,
    },
    data: {
      type: Object as PropType<{
        title: string;
        description: string;
        callback?: any;
      }>,
      required: true,
    },
  },
  methods: {
    close() {
      PopoutsModule.ClosePopout(this.identity);
    },
    buttonClicked() {
      this.data.callback?.();
      this.close();
    },
  },
});
